var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cv-text-input',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":_vm.label,"placeholder":"dd/mm/aaaa","invalid-message":_vm.invalidMessage},model:{value:(_vm.innerInput),callback:function ($$v) {_vm.innerInput=$$v},expression:"innerInput"}}),_c('cv-date-picker',{staticClass:"hidden",attrs:{"kind":"single","pattern":"\\d{1,2}/\\d{1,2}/\\d{4}","placeholder":"dd/mm/aaaa","invalid-message":_vm.invalidMessage,"cal-options":{
      altInput: true,
      altFormat: 'd/m/Y',
      dateFormat: 'Y-m-d 12:00:00',
      defaultDate: Date.now(),
      locale: 'pt',
      disableMobile: 'true',
      minDate: _vm.minDate,
      maxDate: _vm.maxDate }},model:{value:(_vm.inputField),callback:function ($$v) {_vm.inputField=$$v},expression:"inputField"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }