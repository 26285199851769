





































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'

@Component
export default class ServicesByRegional extends Vue {
  @Prop({ default: true, required: false }) label!: boolean
  @Prop({ default: [], required: false }) value!: Array<string>
  regionals = []
  selectedServices: Array<string> = []
  regionalsIds: Array<string> = []

  @Watch('selectedServices')
  onSelectedServicesChange () {
    this.$emit('input', this.selectedServices)
  }

  @Watch('value')
  onValueChange (newVal: Array<string>) {
    this.selectedServices = newVal
  }

  created () {
    axios.get('/services/groupby/regional')
      .then(response => {
        this.regionals = camelCaseKeys(response.data.data, { deep: true })
          .filter((regional: Regional) => regional.services.length)

        this.regionalsIds = this.regionals.reduce((acc: Array<string>, regional: Regional) => {
          return acc.concat(regional.services.map(service => service.id))
        }, [])
      })
  }

  selectAllFromRegional ($e: boolean, regional: Regional) {
    const servicesIds = regional.services.map((service: ServiceRegion) => service.id)

    if ($e === true) {
      servicesIds.forEach((id: string) => {
        if (!this.selectedServices.includes(id)) {
          this.selectedServices.push(id)
        }
      })
    } else {
      this.selectedServices = this.selectedServices.filter(id => !servicesIds.includes(id))
    }
  }

  selectAll () {
    if (this.regionalsIds.length === this.selectedServices.length) {
      this.selectedServices = []
    } else {
      this.selectedServices = [...this.regionalsIds]
    }
  }
}
