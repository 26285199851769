





























import moment from 'moment'
import { Component, Vue, ModelSync, Prop, Watch } from 'vue-property-decorator'
import { mask } from 'vue-the-mask'

@Component({
  directives: { mask }
})
export default class DateSimple extends Vue {
  @ModelSync('value', 'input', { type: String }) inputField!: string
  @Prop({ type: String }) invalidMessage!: string
  @Prop({ type: String }) label!: string
  @Prop({ type: String }) minDate!: string
  @Prop({ type: String }) maxDate!: string

  innerInput = ''

  @Watch('innerInput')
  onInnerInputChange () {
    if (this.innerInput.length === 'DD/MM/YYYY'.length) {
      this.inputField = moment(this.innerInput, 'DD/MM/YYYY').format('YYYY-MM-DD HH:mm:ss')
    }
  }

  @Watch('inputField', { immediate: true })
  changeInput () {
    if (this.inputField && this.inputField.length >= 'YYYY-MM-DD'.length) {
      this.innerInput = moment(this.inputField).format('DD/MM/YYYY')
    }

    if (this.inputField && this.inputField.length === 0) {
      this.innerInput = ''
    }
  }
}
