


















































































































































































import { Component, Mixins } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import axios from 'axios'
import moment from 'moment'
import snakeCaseKeys from 'snakecase-keys'
import { mask } from 'vue-the-mask'

import ServicesByRegionalField from '@/partials/forms/components/ServicesByRegional.vue'
import flashMessageMixin from '@/mixins/flashMessageMixin'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import DateInput from '@/partials/forms/components/DateTime/DateSimple.vue'

@Component({
  directives: { mask },
  components: {
    DateInput,
    ValidationProvider,
    ValidationObserver,
    NotifyOnFailed,
    ServicesByRegionalField,
    PasswordConfirmField
  }
})
export default class ExamsScheduledCreate extends Mixins(flashMessageMixin, ValidatorConfigMixin) {
  form: Record<string, any> = {
    examId: '',
    scheduledTo: moment().format('YYYY-MM-DD HH:mm:ss'),
    availableOn: moment().format('YYYY-MM-DD HH:mm:ss'),
    availableUp: moment().format('YYYY-MM-DD HH:mm:ss'),
    completedAt: null,
    services: [],
    confirm: '',
    errors: {
      confirm: ''
    }
  }

  availableOn = {
    time: {
      hour: '13',
      minutes: '00'
    }
  }

  scheduledTo = {
    time: {
      hour: '13',
      minutes: '00'
    }
  }

  availableUp = '15'

  exams = []
  done = true

  submit () {
    this.done = false
    this.formatFormDates()

    axios.post(`exam/${this.form.examId}/schedule`, snakeCaseKeys(this.form))
      .then(() => {
        const flashMessage = {
          isSuccess: true,
          message: 'Agendamento cadastrado com sucesso.'
        }
        this.$router.push({ name: 'ExamsScheduled' }, () => this.setFlashMessage(flashMessage))
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = response.data.errors
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  created () {
    this.formatFormDates()

    axios.get('exam')
      .then(response => {
        this.exams = response.data.data
      })
  }

  formatFormDates () {
    let { hour, minutes } = this.scheduledTo.time
    this.form.scheduledTo = moment(this.form.scheduledTo).format(`YYYY-MM-DD ${this.leadingZero(hour)}:${this.leadingZero(minutes)}:00`)

    hour = this.availableOn.time.hour
    minutes = this.availableOn.time.minutes

    this.form.availableOn = moment(this.form.availableOn).format(`YYYY-MM-DD ${this.leadingZero(hour)}:${this.leadingZero(minutes)}:00`)

    this.form.availableUp = moment(this.form.scheduledTo).add(this.availableUp, 'minute').format('YYYY-MM-DD HH:mm:00')
  }

  private leadingZero (num: string) {
    return num.length === 2 ? num : '0' + num
  }

  isToday (dateTime: string) {
    return moment(0, 'HH').diff(dateTime, 'days') === 0
  }
}
